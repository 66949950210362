import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loader from "react-loader-spinner";
// import ProtectedRoute from "./ProtectedRoute";
// import moment from "moment";

const PageNotFound = lazy(() => import("./components/common/PageNotFound"));
const Home = lazy(() => import("./components/Home/Home"));

function App() {
  // const auth = () => {
  //   if (sessionStorage.getItem("session")) {
  //     return true;
  //   } else if (localStorage.getItem("session")) {
  //     var sessionDate = JSON.parse(localStorage.getItem("session")).date;
  //     var diff = moment().startOf("day").diff(moment(sessionDate).startOf("day"), "days");
  //     if (diff >= 0 && diff < 1) {
  //       return true;
  //     } else {
  //       // sessionStorage.clear();
  //       localStorage.clear();
  //       return false;
  //     }
  //   } else {
  //     sessionStorage.clear();
  //     localStorage.clear();
  //     return false;
  //   }
  // };

  // const isAuthenticated = auth();

  return (
    <div className="App">
      <Suspense
        fallback={
          <center style={{ paddingTop: "40vh" }}>
            <Loader
              type="TailSpin"
              height={100}
              width={100}
              color="#32c5ff"
              secondaryColor="black"
              // timeout={3000} //3 secs
            />
          </center>
        }>
        <Router>
          <Switch>
            {/* <Route exact path="/" component={Home} /> */}
            <Route exact path="/Home/:surveyId" component={Home} />
            {/* <ProtectedRoute exact path="/Dashboard" component={Dashboard} isAuth={isAuthenticated} />
            <ProtectedRoute
              exact
              path="/EditOrganization/:orgId"
              component={EditOrganization}
              isAuth={isAuthenticated}
            /> */}
            <Route exact path="/PageNotFound" component={PageNotFound} />
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
